@import "../node_modules/maplibre-gl/dist/maplibre-gl.css";
@import "../node_modules/@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";

body {
    margin: 0;
    padding: 0;
}

.map-info {
    display: table;
    position: relative;
    margin: 0px auto;
    word-wrap: anywhere;
    white-space: pre-wrap;
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    color: #222;
    background: #fff;
    }

.maplibregl-map img {    
    max-width: none;
}